import { Input, makeStyles, MenuItem, Select } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {
  getChipDisplayClass,
  getDisplayStatus,
  LOAD_STATUS,
  TRACKER_VISIBLE_STATUSES,
} from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import React, { useCallback, useState } from 'react';
import DriverAssignConfirmation from './DriverAssignConfirmation';
import OrderCancellationConfirmation from './OrderCancellationConfirmation';

type SelectProps = {
  isDispatcher: boolean;
  status: LOAD_STATUS;
  loadId: number;
  linkedLegs: number[];
};

const SelectChip = styled('div')({
  display: 'inline-block',
  padding: '0px 5px',
  height: '20px',
  borderRadius: '10px',
  lineHeight: '20px',
  fontSize: '12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',

  '&.unassigned': {
    backgroundColor: '#F38686',
    color: 'white',
  },
  '&.pickup': {
    backgroundColor: '#FADFA3',
  },
  '&.dropoff': {
    backgroundColor: '#A6E7C1',
  },
  '&.delivered': {
    backgroundColor: '#CAA2EE',
  },
  '&.completed': {
    backgroundColor: '#ACD5F0',
  },
  '&.cancelled': {
    backgroundColor: '#BDBDBD',
  },
});

export const SelectWrapper: React.FC<SelectProps> = props => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { rootStore } = useStores();
  const { isDispatcher, status, loadId, linkedLegs } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<LOAD_STATUS | null>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      event.stopPropagation();
      const newValue = event.target.value as LOAD_STATUS;
      if (newValue !== status) {
        const load = rootStore.loadTableRows.get(`${loadId}`);
        if (load) {
          if (newValue === LOAD_STATUS.UNASSIGNED && linkedLegs.length > 0) {
            setOpenConfirm(true);
            setNewStatus(newValue);
            return;
          }
          if (newValue === LOAD_STATUS.CANCELLED) {
            setOpenCancelModal(true);
            setNewStatus(newValue);
            return;
          }
          const response = await load.updateStatus(newValue);
          if (response && !response.ok) {
            console.log('[DEBUG] SelectWrapper error:', response.errors);
          }
          setOpen(false);
          setOpenConfirm(false);
          setIsLoading(false);
          setNewStatus(null);
        }
      }
    },

    [status, loadId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleConfirm = useCallback(async () => {
    const load = rootStore.loadTableRows.get(`${loadId}`);
    setIsLoading(true);
    if (load) {
      if (newStatus) {
        const response = await load.updateStatus(newStatus);
        if (response && !response.ok) {
          if (
            response.errors &&
            response.errors.driver &&
            response.errors.driver.length > 0
          ) {
            console.log('[DEBUG] SelectWrapper error:', response.errors);
          }
        }
      }
      setOpen(false);
      setOpenConfirm(false);
      setIsLoading(false);
      setNewStatus(null);
    }
  }, [loadId, newStatus, rootStore.loadTableRows]);

  const handleCancelConfirm = useCallback(
    async (description: any) => {
      const load = rootStore.loadTableRows.get(`${loadId}`);
      setIsLoading(true);
      if (load) {
        if (newStatus) {
          const response = await load.updateStatus(newStatus, description);
          if (response && !response.ok) {
            if (
              response.errors &&
              response.errors.driver &&
              response.errors.driver.length > 0
            ) {
              console.log('[DEBUG] SelectWrapper error:', response.errors);
            }
          }
        }
        setOpen(false);
        setOpenConfirm(false);
        setOpenCancelModal(false);
        setIsLoading(false);
        setNewStatus(null);
      }
    },
    [loadId, newStatus, rootStore.loadTableRows],
  );

  const handleCancel = useCallback(async () => {
    setOpenConfirm(false);
    setOpenCancelModal(false);
    setOpen(false);
    setNewStatus(null);
  }, []);

  const handleOpen = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // setOpen(!open);
    setOpen(true);
  }, []);

  const handleMouseDown = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  const iconComponent = useCallback(
    () => (
      <span onClick={handleOpen}>
        {!isDispatcher ? null : open ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        )}
      </span>
    ),
    [handleOpen, isDispatcher, open],
  );

  return (
    <>
      <Select
        className={classes.select}
        fullWidth
        input={<Input disableUnderline />}
        onChange={handleChange}
        // onClick={handleClick}
        disabled={!isDispatcher}
        value={status}
        open={open}
        IconComponent={iconComponent}
        onMouseDown={handleMouseDown}
      >
        {TRACKER_VISIBLE_STATUSES.map((stat: any) => (
          <MenuItem
            disabled={stat === LOAD_STATUS.PENDING_ACCEPTANCE}
            key={stat}
            value={stat}
          >
            <SelectChip className={getChipDisplayClass(stat)}>
              {getDisplayStatus(stat)}
            </SelectChip>
          </MenuItem>
        ))}
      </Select>
      {!open && openConfirm && (
        <DriverAssignConfirmation
          loading={isLoading}
          legId={loadId}
          driver={'No driver assigned'}
          legInfoList={linkedLegs}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      {!open && openCancelModal && (
        <OrderCancellationConfirmation
          loading={isLoading}
          onConfirm={handleCancelConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    '& > .MuiSelect-select.MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingRight: 0,
    },
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default React.memo(SelectWrapper);
