import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  defaultTime: number;
  trackedObject?: any;
  onTimeElapsed(): void;
};

const CountdownTimer: React.FC<Props> = ({
  defaultTime,
  onTimeElapsed,
  trackedObject,
}) => {
  const classes = useStyles();
  const refreshCountdownRef = useRef<number | undefined>(undefined);
  const [time, setTime] = useState(defaultTime);

  useEffect(() => {
    refreshCountdownRef.current = setInterval(() => {
      setTime(prevTime => {
        let nextTime = prevTime - 1;
        if (nextTime === 0) {
          onTimeElapsed();
          nextTime = defaultTime;
        }
        return nextTime;
      });
    }, 1000);

    return () => {
      clearInterval(refreshCountdownRef.current);
      refreshCountdownRef.current = undefined;
    };
  }, [defaultTime, onTimeElapsed]);

  useEffect(() => {
    setTime(defaultTime);
  }, [defaultTime, trackedObject]);

  const handleRefreshNow = useCallback(() => {
    onTimeElapsed();
    setTime(defaultTime);
  }, [defaultTime, onTimeElapsed]);

  return (
    <Paper className={classes.refresh}>
      <Typography component="p">
        Screen refreshing in: {time}s
        <Button className={classes.button} onClick={handleRefreshNow}>
          Refresh load
        </Button>
      </Typography>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  refresh: {
    background: theme.palette.grey[100],
    margin: theme.spacing(1),
    padding: theme.spacing(0.5),
  },
  button: {
    fontWeight: 700,
    marginLeft: theme.spacing(0.5),
    background: '#0d374b',
    color: 'white',
    '&:hover': {
      color: 'black',
    },
  },
}));

export default CountdownTimer;
