import { LoadTableRow } from 'doc-mate-store/lib/models';
import { FetchLoadQuery } from 'doc-mate-store/lib/models/LoadQuery';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Row,
  TableInstance,
  useBlockLayout,
  useColumnOrder,
  useFilters as useFiltersRT,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import Text from '../../components/DataTable/cells/Text';
import { DataTableContext } from '../../contexts';
import { useWebStores } from '../../hooks';
import {
  useColumns,
  useDefaultColumnOrdering,
  useFilterFields,
  useFilters,
} from '../../hooks/filtering';
import { DateFilterValue, FilterAndColumn } from '../../types/filtering';

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  filterAndColumns: FilterAndColumn<LoadTableRow>[];
  onFilterChange: (query: FetchLoadQuery) => void;
};

const DataTableProvider: React.FC<Props> = ({
  children,
  filterAndColumns,
  onFilterChange,
}) => {
  const location = useLocation();
  const query = qs.parse(location.search.substring(1));
  const { table } = query;

  const { webStore } = useWebStores();
  const { presetTables, savedTables } = webStore;

  const savedTable = useMemo(
    () => (table ? savedTables.get(parseInt(table as string, 10)) : undefined),
    [table, savedTables],
  );
  const preset = useMemo(
    () => (!savedTable ? (table as string) || 'active' : undefined),
    [savedTable, table],
  );

  // Force data to update when the loads map in the root store changes.
  /* eslint-disable react-hooks/exhaustive-deps */
  const loads = webStore.visibleLoadIds.map(loadRef => loadRef.current);
  const data = useMemo(() => {
    return loads;
  }, [JSON.stringify(toJS(loads))]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const columns = useColumns(filterAndColumns);
  const filterFields = useFilterFields(filterAndColumns);
  const defaultColumnOrdering = useDefaultColumnOrdering(filterAndColumns);

  const defaultColumn = useMemo(
    () => ({
      Cell: Text,
      disableFilters: true,
      minWidth: 135,
      width: 200,
      maxWidth: 2000,
    }),
    [],
  );

  const filterTypes = useMemo(
    () => ({
      link: (rows: Row<LoadTableRow>[], id: string, filterValue: string) => {
        return rows.filter(row => {
          const rowValue = row.values[id][1];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .indexOf(String(filterValue).toLowerCase()) >= 0
            : true;
        });
      },
      timeRangeEta: (
        rows: Row<LoadTableRow>[],
        id: string,
        filterValue?: DateFilterValue,
      ) => {
        if (!filterValue) {
          return true;
        }
        return rows.filter(row => {
          // const { from, to } = filterValue;
          const rowValue = row.values[id];
          if (!rowValue) {
            return false;
          }
          // const dateValue: number = moment(rowValue[1].lower).hour();
          // const dateValueMinutes: number = moment(rowValue[1].lower).minute();

          // return dateValue !== undefined
          //   ? from.hour() <= to.hour()
          //     ? !!dateValueMinutes
          //       ? dateValue >= from.hour() && dateValue < to.hour()
          //       : dateValue >= from.hour() && dateValue <= to.hour()
          //     : !!dateValueMinutes
          //     ? dateValue >= from.hour() || dateValue < to.hour()
          //     : dateValue >= from.hour() || dateValue <= to.hour()
          //   : false;
          return true;
        });
        // return true;
      },
      dateRangeEta: (
        rows: Row<LoadTableRow>[],
        id: string,
        filterValue?: DateFilterValue,
      ) => {
        if (!filterValue) {
          return true;
        }
        return rows.filter(row => {
          const { from, to } = filterValue;
          const rowValue: Moment = moment(row.values[id][2]);
          return rowValue !== undefined
            ? rowValue >= from && rowValue <= to
            : false;
        });
      },
      date: (
        rows: Row<LoadTableRow>[],
        id: string,
        filterValue?: DateFilterValue,
      ) => {
        if (!filterValue) {
          return true;
        }
        return rows.filter(row => {
          const { from, to } = filterValue;
          const rowValue = row.values[id];
          console.log(from, to, rowValue);
          if (!rowValue) {
            return false;
          }
          const dateValue: Moment = moment(rowValue);

          return dateValue !== undefined
            ? dateValue >= from && dateValue <= to
            : false;
        });
      },

      dateRange: (
        rows: Row<LoadTableRow>[],
        id: string,
        filterValue?: DateFilterValue,
      ) => {
        if (!filterValue) {
          return true;
        }
        return rows.filter(row => {
          const { from, to } = filterValue;
          const rowValue = row.values[id];
          console.log(from, to, rowValue);
          if (!rowValue) {
            return false;
          }
          const dateValue: Moment = moment(rowValue.lower);

          return dateValue !== undefined
            ? dateValue >= from && dateValue <= to
            : false;
        });
      },
      textSelect: (
        rows: Row<LoadTableRow>[],
        id: string,
        filterValue: string | string[],
      ) => {
        if (filterValue.length === 0) {
          return true;
        }
        if (typeof filterValue === 'string') {
          return rows.filter(row => {
            const rowValue = row.values[id];
            return rowValue.toLowerCase().includes(filterValue.toLowerCase());
          });
        }
        return rows.filter(row => {
          const rowValue = row.values[id];
          return filterValue.includes(rowValue);
        });
      },
      textSelectFirstElement: (
        rows: Row<LoadTableRow>[],
        id: string,
        filterValue: string | string[],
      ) => {
        if (filterValue.length === 0) {
          return true;
        }
        if (typeof filterValue === 'string') {
          return rows.filter(row => {
            const rowValue = row.values[id][0];
            return rowValue.toLowerCase().includes(filterValue.toLowerCase());
          });
        }
        return rows.filter(row => {
          const rowValue = row.values[id][0];
          return filterValue.includes(rowValue);
        });
      },
    }),
    [],
  );

  const tableProps = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn,
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFiltersRT,
    useSortBy,
    useResizeColumns,
    useColumnOrder,
    useBlockLayout,
    // useFlexLayout,
  );
  const { setHiddenColumns, setColumnOrder } = tableProps;

  const defaultHiddenColumns = useMemo(() => {
    return columns
      .filter(column => defaultColumnOrdering.indexOf(column.id!) < 0)
      .map(column => column.id!);
  }, [columns, defaultColumnOrdering]);

  const presetTableColumnOrdering: string[] = useMemo(() => {
    if (
      preset &&
      webStore.presetTables &&
      webStore.presetTables[preset] &&
      webStore.presetTables[preset].column_ordering !== null
    ) {
      return webStore.presetTables[preset].column_ordering!;
    }
    return defaultColumnOrdering;
  }, [defaultColumnOrdering, preset, webStore.presetTables]);

  // Reset columns when selected saved table or preset has changed.
  useEffect(() => {
    let columnOrdering: string[];
    if (savedTable) {
      columnOrdering = savedTable.column_ordering;
    } else if (presetTableColumnOrdering) {
      columnOrdering = presetTableColumnOrdering;
    } else {
      columnOrdering = defaultColumnOrdering;
    }

    const hiddenColumns = columns
      .filter(column => columnOrdering.indexOf(column.id!) < 0)
      .map(column => column.id!);

    setHiddenColumns(hiddenColumns);
    setColumnOrder(columnOrdering);
  }, [
    columns,
    defaultColumnOrdering,
    presetTableColumnOrdering,
    savedTable,
    setColumnOrder,
    setHiddenColumns,
    tableProps,
  ]);

  const resetColumns = useCallback(() => {
    setHiddenColumns(defaultHiddenColumns);
    setColumnOrder(defaultColumnOrdering);
  }, [
    defaultColumnOrdering,
    defaultHiddenColumns,
    setColumnOrder,
    setHiddenColumns,
  ]);

  // Create new saved table.
  const createSavedTable = useCallback(async () => {
    webStore.setFilterDrawer(true);
    return webStore.createSavedTable(defaultColumnOrdering);
  }, [defaultColumnOrdering, webStore]);

  // Update saved table in API if visible columns or ordering have changed.
  const saveColumnOrdering = useCallback(
    async (columnIds?: string[]) => {
      if (savedTable) {
        await savedTable.setColumnOrdering(columnIds || defaultColumnOrdering);
      } else {
        await webStore.updatePresetTable(columnIds || defaultColumnOrdering);
      }
    },
    [defaultColumnOrdering, savedTable, webStore],
  );

  const {
    editable,
    filters,
    getValue,
    setValue,
    deleteValue,
    clearValues,
    setValues,
    visibleFilters,
    handleUpdateFilters,
  } = useFilters(
    filterFields,
    onFilterChange,
    savedTable,
    preset as string,
    presetTables,
  );

  return (
    <DataTableContext.Provider
      value={{
        savedTables,
        savedTable,
        presetTables,
        preset: preset as string,
        editable,
        filters,
        getValue,
        setValue,
        deleteValue,
        clearValues,
        setValues,
        tableProps: (tableProps as unknown) as TableInstance<{}>,
        resetColumns,
        createSavedTable,
        saveColumnOrdering,
        visibleFilters,
        handleUpdateFilters,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
};

export default observer(DataTableProvider);
