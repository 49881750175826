import { Divider, Grid, makeStyles } from '@material-ui/core';
import { LOAD_STATUS } from 'doc-mate-store/lib/constants/load';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import pluralize from 'pluralize';
import React from 'react';
import Labeled from '../Labeled';
import LoadLocationMultiLeg from '../LoadLocation/multileg';
import TruckHead from './TruckHead.svg';
import TruckTail from './TruckTail.svg';
type Props = {
  load: OrderLeg;
  lastLoad: OrderLeg;
};

const LoadDetailMultiLeg: React.FC<Props> = ({ load, lastLoad }) => {
  const classes = useStyles();
  const isLoadDeliveredSplit =
    load.status === LOAD_STATUS.DELIVERED ||
    load.status === LOAD_STATUS.COMPLETED;

  return (
    <div>
      <LoadLocationMultiLeg
        showLabel
        load={load}
        lastLoad={lastLoad}
        className={classes.loadLocation}
      />
      <Grid container>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Pick-up Window">
            {load.pickupWindow &&
              moment(load.pickupWindow.lower).format('MM-DD-YYYY')}
            &nbsp;
            {load.pickupWindow &&
              moment(load.pickupWindow.lower).format('HH:mm')}
            &nbsp;&ndash;&nbsp;
            {load.pickupWindow &&
              moment(load.pickupWindow.upper).format('HH:mm')}
          </Labeled>
        </Grid>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Drop-off Window">
            {lastLoad.dropoffWindow &&
              moment(lastLoad.dropoffWindow.lower).format('MM-DD-YYYY')}
            &nbsp;
            {lastLoad.dropoffWindow &&
              moment(lastLoad.dropoffWindow.lower).format('HH:mm')}
            &ndash;
            {lastLoad.dropoffWindow &&
              moment(lastLoad.dropoffWindow.upper).format('HH:mm')}
          </Labeled>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Pick-up No., Shipper">
            {(load.order.maybeCurrent && load.order.current.poNumberShipper) ||
              'Not Provided'}
          </Labeled>
        </Grid>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Pick-up No., Consignee">
            {(load.order.maybeCurrent &&
              load.order.current.poNumberConsignee) ||
              'Not provided'}
          </Labeled>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.loadDetailItem}>
        <Labeled label="Bill of Lading Number">
          {load.billOfLadingNumber || 'To be provided'}
        </Labeled>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Driver">
            {!isLoadDeliveredSplit
              ? load.driver && load.driver.isValid && load.driver.current.name
              : lastLoad.driver &&
                lastLoad.driver.isValid &&
                lastLoad.driver.current.name}
          </Labeled>
        </Grid>
        {/* <Grid
          item
          xs={6}
          className={[classes.loadDetailItem, classes.loadDetailItem2].join(
            ' ',
          )}
        >
          <Labeled label="CDL Number">
            {!isLoadDeliveredSplit
              ? load.driver &&
                load.driver.isValid &&
                load.driver.current.cdlNumber
              : lastLoad.driver &&
                lastLoad.driver.isValid &&
                lastLoad.driver.current.cdlNumber}
          </Labeled>
        </Grid> */}
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <img src={TruckHead} alt="Truck" title="Truck Number" />
          &nbsp;
          {!isLoadDeliveredSplit
            ? load.truck && load.truck.isValid
              ? load.truck.current.name
              : 'Not provided'
            : lastLoad.truck && lastLoad.truck.isValid
            ? lastLoad.truck.current.name
            : 'Not provided'}
        </Grid>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <img src={TruckTail} alt="Truck" title="Trailer Number" />
          &nbsp;{load.trailerNumber || 'Not provided'}
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Commodity">
            {load.loadType && load.loadType.isValid
              ? load.loadType.current.name
              : 'N/A'}
          </Labeled>
        </Grid>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="Total Load Weight">
            {load.loadWeight && (
              <span>
                {load.loadWeight} {load.loadWeightUnit!.current.name}
              </span>
            )}
            {!load.loadWeight && <span>N/A</span>}
          </Labeled>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled
            label={`# of ${
              load.loadQuantityUnit && load.loadQuantityUnit.isValid
                ? pluralize(
                    load.loadQuantityUnit.current.name,
                    load.loadQuantity || 0,
                  )
                : 'pallets'
            }`}
          >
            {load.loadQuantity}
          </Labeled>
        </Grid>
        <Grid item xs={6} className={classes.loadDetailItem}>
          <Labeled label="L x W x H">
            {load.loadLength || load.loadWidth || load.loadHeight ? (
              <span>
                {[
                  load.loadLength || '?',
                  load.loadWidth || '?',
                  load.loadHeight || '?',
                ].join(' x ')}
              </span>
            ) : (
              <span>
                N/A &ndash;{' '}
                {load.loadQuantityUnit &&
                  load.loadQuantityUnit.isValid &&
                  pluralize(load.loadQuantityUnit.current.name)}
              </span>
            )}
          </Labeled>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.loadDetailItem}>
        <Labeled label="Required Equipment">
          {Array.from(load.specialEquipment).length > 0
            ? Array.from(load.specialEquipment)
                .map(equipment => equipment.name)
                .join(', ')
            : 'Not provided'}
        </Labeled>
      </div>
      <Divider />
      <div className={classes.loadDetailItem}>
        <Labeled label="Product description">
          {load.loadDescription || 'N/A'}
        </Labeled>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  loadDetailItem: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    // fontWeight: theme.typography.fontWeightMedium,
    fontWeight: 500,
  },
  loadDetailItem2: {
    alignItems: 'start',
  },
  loadLocation: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
}));

export default observer(LoadDetailMultiLeg);
