import { Backdrop, CircularProgress } from '@material-ui/core';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import DataTable from '../../components/DataTable';

type Props = {
  fetching: boolean;
  loading: boolean;
};

const Table: React.FC<Props> = props => {
  const { fetching, loading } = props;
  const location = useLocation();

  const rowProps = useCallback(
    (row: { original: any }) => ({
      onDoubleClick: (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(
          `/l/${(row.original as OrderLeg).orderLegLinkId}${location.search}`,
          '_blank',
          'noopener,noreferrer',
        );
        return false;
      },
    }),
    [location.search],
  );
  if (loading) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <DataTable
      fetching={fetching}
      // tableProps={tableProps}
      getRowProps={rowProps}
    />
  );
};

export default React.memo(observer(Table));
