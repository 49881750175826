import {
  AppBar,
  IconButton,
  makeStyles,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LinkIcon from '@material-ui/icons/Link';
import config from 'doc-mate-store/lib/config';
import { useStores } from 'doc-mate-store/lib/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { Email } from 'react-obfuscate-email';
import { useHistory, useLocation } from 'react-router';
import { colors } from '../../themes';
type Props = {
  flex?: number;
  loadId: number | string;
  showBackButton?: boolean;
};

const LoadAppBar: React.FC<Props> = ({ flex, loadId, showBackButton }) => {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { rootStore } = useStores();
  const { me } = rootStore;
  const leg = rootStore.trackedLeg;
  const user = me ? me.maybeCurrent : undefined;
  // const orderLegsArray = Array.from(orderlegs.values()).filter(
  //   orderLeg => orderLeg.manifestId === leg?.manifestId,
  // );

  const [copy, setCopy] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 1000);
    }
  }, [copy]);
  const handleBack = useCallback(() => {
    history.push(`/${location.search}`);
  }, [history, location.search]);

  const handleCopyAllLinks = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      navigator.clipboard.writeText(
        `${config.urls.web}l/${leg!.orderLegLinkId}?show_all=True`,
      );
      setCopy(true);
      setAnchorEl(event.currentTarget);
    },
    [leg],
  );

  // const handleNext = useCallback(() => {
  //   if (orderLegsArray) {
  //     const index = orderLegsArray.findIndex(
  //       orderLeg => orderLeg.id === leg?.id,
  //     );
  //     if (index < orderLegsArray.length - 1) {
  //       history.replace(`/l/${orderLegsArray[index + 1].orderLegLinkId}`);
  //     } else {
  //       history.replace(`/l/${orderLegsArray[0].orderLegLinkId}`);
  //     }
  //   }
  // }, [history, leg, orderLegsArray]);

  return (
    <AppBar position="static" className={classes.appBar} style={{ flex }}>
      <Toolbar className={classes.toolbar} variant="dense">
        {showBackButton && (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleBack}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}

        <Typography variant="h3" noWrap className={classes.text}>
          Manifest # {loadId}
          {/* {orderLegsArray.length > 1 && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleNext}
            >
              <ArrowBackIosIcon style={{ rotate: '180deg' }} />
            </IconButton>
          )} */}
        </Typography>
        {leg && user && (user.isDispatcher || user.isBroker) && (
          <>
            <Tooltip title="Copy All Links">
              <div onClick={handleCopyAllLinks} style={{ cursor: 'pointer' }}>
                <LinkIcon style={{ width: 30, height: 30 }} />
              </div>
            </Tooltip>
            <Popover
              id={`${leg.orderLegLinkId}-copy`}
              open={copy}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className={classes.popUpText}>Link Copied</div>
            </Popover>
          </>
        )}

        {leg && user && user.isDispatcher && (
          <Email
            email=""
            subject={`Sharing Doc-Mate Manifest #${loadId}`}
            body={`Hello,\n\n
            Your items have been assigned to a driver.
            Please track the progress at this link: ${config.urls.web}/l/${leg.orderLegLinkId}`}
          >
            <img
              src="/ShareIcon.png"
              alt="Share"
              width={40}
              height={40}
              style={{ cursor: 'pointer' }}
            />
          </Email>
        )}
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: colors.primary[200],
    color: colors.primary[1000],
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      position: 'static',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {},
  text: {
    flexGrow: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default LoadAppBar;
