import { GPSPositionData } from 'doc-mate-store/lib/models/GPSPosition';
import { OrderLeg } from 'doc-mate-store/lib/models/OrderLeg';
import fb from 'doc-mate-store/lib/services/firebase';
import React, { useEffect, useRef } from 'react';

type Props = {
  loads: OrderLeg[];
  onPathInsert(newPositions: GPSPositionData[]): void;
  onPathReset(positions: GPSPositionData[]): void;
  legLinkId: string | undefined;
  onlyShowLinkId?: boolean;
};

let newPaths: GPSPositionData[] = [];

function process(username: string, keys: string[], loadId: number) {
  const usernameCleaned = username.replaceAll('@', '').replaceAll('.', '');
  const posRef = fb.db.ref(`users/${usernameCleaned}/positions`);
  const promises = keys.map(key => posRef.child(key).once('value'));
  return Promise.all(promises).then(snapshots => {
    snapshots.forEach((snapshot: { val: () => any }) => {
      const pval: GPSPositionData = snapshot.val();

      if (pval) {
        newPaths.push({ ...pval, loadId: loadId });
      }
    });
    return newPaths;
  });
}

const DriverPath: React.FC<Props> = ({
  loads,
  onPathInsert,
  onPathReset,
  legLinkId,
  onlyShowLinkId = false,
}) => {
  const pathRef = useRef<any>();
  // const pathRef = useRef<firebase.database.Reference>();

  useEffect(() => {
    newPaths = [];

    loads.forEach(load => {
      if (
        !load ||
        !load.driver ||
        !load.driver.isValid ||
        (load.orderLegLinkId !== legLinkId && onlyShowLinkId)
      ) {
        return;
      }

      const username = load.driver.id;

      pathRef.current = fb.db.ref(`loads/${load!.id}/positions`);
      pathRef.current.once('value', (snapshot: { val: () => any }) => {
        const positionSnapshot = snapshot.val();
        if (positionSnapshot) {
          process(username, Object.keys(positionSnapshot), load.id).then(
            path => {
              onPathReset(path);
              if (pathRef.current) {
                pathRef.current
                  .limitToLast(2)
                  .on('child_added', (snapshot: { key: string }) => {
                    // to handle the "initial" data we created
                    if (snapshot.key) {
                      process(username, [snapshot.key], load.id).then(
                        additionalPath => {
                          onPathInsert(additionalPath);
                        },
                      );
                    }
                  });
              }
            },
          );
        }
      });
    });

    return () => {
      if (pathRef.current) {
        onPathReset([]);
        pathRef.current.off();
      }
    };
  }, [onPathInsert, onPathReset, loads, legLinkId, onlyShowLinkId]);

  return null;
};

export default DriverPath;
