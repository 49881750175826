import {
  Button,
  ClickAwayListener,
  Divider,
  Theme,
  darken,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {
  ON_ASSIGNMENT_STATUS_FROM_API,
  ON_ASSIGNMENT_STATUS_TEXT,
  ON_TIME_STATUS_FROM_API,
  ON_TIME_STATUS_SHORT_TEXT,
  VISIBLE_ID_FETCH_LIMIT,
} from 'doc-mate-store/lib/constants/load';
import { useStores } from 'doc-mate-store/lib/hooks';
import { User } from 'doc-mate-store/lib/models';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import pluralize from 'pluralize';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FilterButton from '../../components/FilterButton';
import FilterDrawer from '../../components/FilterDrawer';
import TrackerStatusBar from '../../components/TrackerStatusBar';
import RefreshIcon from '../../components/icons/Refresh.svg';
import { DataTableContext } from '../../contexts';
import { useWebStores } from '../../hooks';
import { colors, onTimeStatusColors } from '../../themes';
import Table from './Table';

type Props = {
  fetching: boolean;
  filterDrawerOpen: boolean;
  loading: boolean;
  pageNumber: number;
  count: number;
  onPrevPage: () => void;
  onNextPage: () => void;
  onNewestPage: () => void;
  onOldestPage: () => void;
  onFilterDrawerOpen: (filterDrawerOpen: boolean) => void;
  onLoadBuilderOpen: () => void;
  onForceReload: () => void;
  me?: User;
};

const Content: React.FC<Props> = ({
  fetching,
  filterDrawerOpen,
  loading,
  pageNumber,
  count,
  onPrevPage,
  onNextPage,
  onNewestPage,
  onOldestPage,
  onFilterDrawerOpen,
  onLoadBuilderOpen,
  onForceReload,
  me,
}) => {
  const {
    preset,
    presetTables,
    savedTable,
    tableProps,
    resetColumns,
  } = useContext(DataTableContext);
  const theme = useTheme();
  const classes = useStyles();
  const {
    preFilteredRows,
    // filteredRows,
    setAllFilters,

    state: { filters },
  } = tableProps;
  const tableRef = useRef<HTMLDivElement>(null);
  const { rootStore } = useStores();
  const { webStore } = useWebStores();
  const { columnFilters } = webStore;
  const [showModal, setShowModal] = useState(false);
  const minRow = Math.min(pageNumber * VISIBLE_ID_FETCH_LIMIT + 1, count);
  const maxRow = Math.min((pageNumber + 1) * VISIBLE_ID_FETCH_LIMIT, count);
  const disablePrev = minRow < VISIBLE_ID_FETCH_LIMIT;
  const disableNext = maxRow >= count;
  const [showNotifications, setShowNotifications] = useState(false);

  const handleClearLocalSearchClick = useCallback(() => {
    setAllFilters([]);
    columnFilters!.reset();
  }, [setAllFilters, columnFilters]);

  // const handleScroll = useCallback(() => {
  //   if (tableRef.current && !loading) {
  //     const { offsetHeight, scrollTop, scrollHeight } = tableRef.current;
  //     if (
  //       scrollTop + offsetHeight >= scrollHeight &&
  //       !apiLoading &&
  //       !fetching
  //     ) {
  //       onFetchMoreRows();
  //     }
  //   }
  // }, [apiLoading, fetching, loading, onFetchMoreRows]);

  // useEffect(() => {
  //   let r: any = undefined;
  //   if (tableRef.current) {
  //     handleScroll();
  //     window.addEventListener('resize', handleScroll);
  //     tableRef.current.addEventListener('scroll', handleScroll);
  //     r = tableRef.current;
  //   }
  //   return () => {
  //     if (r) {
  //       window.removeEventListener('resize', handleScroll);
  //       r.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [handleScroll]);

  const isRejected = preset === 'rejected';

  let title = '';
  let subtitle = '';
  let subtitleColor: string | undefined = undefined;
  if (savedTable) {
    title = savedTable.name;
  } else if (preset) {
    subtitle = ON_TIME_STATUS_SHORT_TEXT[ON_TIME_STATUS_FROM_API[preset]];
    subtitleColor = onTimeStatusColors(theme)[ON_TIME_STATUS_FROM_API[preset]];
    if (preset.startsWith('pickup_')) {
      title = 'Pick-up';
    } else if (preset.startsWith('dropoff_')) {
      title = 'Drop-off';
    } else if (preset === 'active') {
      title = 'Active Loads';
    } else if (preset === 'delivered' || preset === 'completed') {
      title = 'Loads';
    } else if (preset === 'all') {
      title = 'All Loads';
    } else if (preset.startsWith('assignment_')) {
      title = ON_ASSIGNMENT_STATUS_TEXT[ON_ASSIGNMENT_STATUS_FROM_API[preset]];
      subtitle = '';
    } else if (preset === 'rejected') {
      title = 'Driver Rejected Loads';
    } else if (preset === 'stale') {
      title = 'Stale Loads';
    }
  }

  const itemCount = savedTable
    ? savedTable.results
    : presetTables && preset
    ? presetTables[preset].results
    : preFilteredRows.length;
  const isSearchingLocally = !isEmpty(filters);

  const handleNewestPage = useCallback(() => {
    if (!disablePrev) {
      onNewestPage();
    }
  }, [disablePrev, onNewestPage]);

  const handleOldestPage = useCallback(() => {
    if (!disableNext) {
      onOldestPage();
    }
  }, [disableNext, onOldestPage]);

  const handleMouseEnter = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleForceReload = useCallback(() => {
    onForceReload();
  }, [onForceReload]);

  const handleNotificationClick = useCallback(async () => {
    setShowNotifications(true);
    await rootStore.bulkNotificationRead();
  }, [rootStore]);

  const handleDeleteAllNotifications = useCallback(async () => {
    setShowNotifications(false);
    await rootStore.bulkNotificationDelete();
  }, [rootStore]);

  useEffect(() => {
    (async () => {
      await rootStore.fetchNotifications();
      await rootStore.fetchUnreadNotificationCount();
    })();
  }, [rootStore]);

  useEffect(() => {
    if (isRejected && !loading) {
      resetColumns();
    }
  }, [isRejected, loading, resetColumns]);

  return (
    <div className={classes.root}>
      <TrackerStatusBar loading={loading || fetching} me={me} />
      {!isRejected && (
        <FilterDrawer
          className={!filterDrawerOpen ? classes.drawerClosed : ''}
          defaultVisible={['manifestId']}
        />
      )}
      <div className={classes.headerAndTable}>
        <div className={classes.header}>
          {!isRejected && (
            <FilterButton open={filterDrawerOpen} onOpen={onFilterDrawerOpen} />
          )}
          <div className={classes.tableTitle}>
            {title}
            {subtitle && (
              <>
                : <span style={{ color: subtitleColor }}>{subtitle}</span>
              </>
            )}
            &nbsp;&nbsp;({itemCount} {pluralize('Load', itemCount)})
          </div>
          <ClickAwayListener onClickAway={() => setShowNotifications(false)}>
            <div className={classes.notificationContainer}>
              {rootStore.unreadNotificationCount > 0 ? (
                <NotificationImportantIcon
                  className={classes.notificationImportantIcon}
                  onClick={handleNotificationClick}
                />
              ) : (
                <NotificationsNoneIcon
                  className={classes.notificationIcon}
                  onClick={handleNotificationClick}
                />
              )}

              {showNotifications && (
                <div className={classes.dropdown}>
                  {rootStore.notifications.size > 0 ? (
                    <>
                      {Array.from(rootStore.notifications.values())
                        .reverse()
                        .map(notification => (
                          <div
                            key={notification.id}
                            style={{ textAlign: 'center' }}
                          >
                            <div
                              style={{ textAlign: 'center', fontWeight: 600 }}
                            >
                              {`${new Date(
                                notification.createdAt,
                              ).toLocaleDateString()} - ${new Date(
                                notification.createdAt,
                              ).toLocaleTimeString()}`}
                            </div>
                            {notification.message}
                            <Divider
                              style={{ marginTop: 4, marginBottom: 4 }}
                            />
                          </div>
                        ))}
                      <Button
                        onClick={handleDeleteAllNotifications}
                        style={{ marginLeft: 12 }}
                      >
                        Delete Notifications
                      </Button>
                    </>
                  ) : (
                    <div style={{ textAlign: 'center' }}>No Notifications</div>
                  )}
                </div>
              )}
            </div>
          </ClickAwayListener>

          <div className={classes.refreshContainer}>
            <img
              onClick={handleForceReload}
              className={classes.refresh}
              src={RefreshIcon}
              alt="Refresh"
            />
          </div>
          {me && (me.isDispatcher || me.isBroker) && (
            <div className={classes.dispatcherActions}>
              <Button
                className={classes.dispatcherActionButton}
                onClick={onLoadBuilderOpen}
              >
                <AddIcon className={classes.dispatcherActionButtonIcon} />
                New Manifest
              </Button>
            </div>
          )}

          {isSearchingLocally && !loading && (
            <div className={classes.localSearch}>
              <span>
                Searching from {title}
                {subtitle && `: ${subtitle}`}
              </span>
              <span className={classes.results}>
                {count} {pluralize('result', count)}
              </span>
              <Button
                className={classes.clear}
                onClick={handleClearLocalSearchClick}
                size="small"
              >
                Clear Search
              </Button>
            </div>
          )}
        </div>
        <div className={classes.noteContainer}>
          <div className={classes.note}>
            *Hold shift when clicking on a column header to multi-sort
          </div>
          {!loading && (
            <div
              className={classes.navigationContainer}
              onMouseLeave={handleMouseLeave}
            >
              <span onMouseEnter={handleMouseEnter}>
                {minRow} - {maxRow} of {count}
                {!!showModal && (
                  <div className={classes.modal}>
                    <div
                      className={[
                        classes.modalText,
                        !!disablePrev && classes.disabledModalText,
                      ].join(' ')}
                      onClick={handleNewestPage}
                    >
                      Newest
                    </div>
                    <div
                      className={[
                        classes.modalText,
                        !!disableNext && classes.disabledModalText,
                      ].join(' ')}
                      onClick={handleOldestPage}
                    >
                      Oldest
                    </div>
                  </div>
                )}
              </span>

              <Button onClick={onPrevPage} disabled={disablePrev}>
                <ChevronLeftIcon />
              </Button>
              <Button onClick={onNextPage} disabled={disableNext}>
                <ChevronRightIcon />
              </Button>
            </div>
          )}
        </div>

        <div className={classes.table} ref={tableRef}>
          <Table fetching={fetching} loading={loading} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'hidden',
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    paddingLeft: 20,
    justifyContent: 'center',
  },
  note: {
    flex: 1,
  },
  navigationContainer: {
    alignSelf: 'flex-end',
  },
  table: {
    borderTopColor: colors.primary[600],
    borderTopWidth: 12,
    borderTopStyle: 'solid',
    flex: 1,
    marginTop: theme.spacing(6),
    overflow: 'auto',

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  tableTitle: {
    ...theme.typography.h4,
    flex: 1,
    fontWeight: 700,
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  drawerClosed: {
    border: 0,
    marginLeft: -319,
    transition: theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(1, 0),
  },
  headerAndTable: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  drawerPaper: {
    width: 315,
  },
  clear: {},
  results: {},
  localSearch: {
    ...theme.typography.h4,
    fontWeight: 400,
    fontSize: 18,
    background: colors.textPrimary[400],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.getContrastText(colors.textPrimary[400]),
    height: '100%',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0.5, 1.5),
    textTransform: 'uppercase',

    '& span, & button': {
      display: 'inline-block',
      verticalAlign: 'middle',
    },

    '& $results': {
      // fontWeight: theme.typography.fontWeightMedium,
      fontWeight: 500,
      margin: theme.spacing(0, 2),
    },

    '& $clear': {
      backgroundColor: colors.primary[100],
    },
  },
  dispatcherActions: {},
  refreshContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationImportantIcon: {
    alignSelf: 'center',
    width: 32,
    height: 32,
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#ffffff', 0.15),
    },
    fill: colors.danger[600],
  },
  notificationIcon: {
    alignSelf: 'center',
    width: 32,
    height: 32,
    marginTop: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#ffffff', 0.15),
    },
    fill: colors.primary[600],
  },
  refresh: {
    alignSelf: 'center',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#ffffff', 0.15),
    },
  },
  dispatcherActionButton: {
    backgroundColor: colors.primary[600],
    color: 'white',
    fontSize: theme.typography.body1.fontSize,
    marginRight: theme.spacing(3),

    '&:hover': {
      backgroundColor: darken(colors.primary[600], 0.15),
    },
  },
  dispatcherActionButtonIcon: {
    marginRight: theme.spacing(1),
  },
  modal: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 160,
    height: 75,
    zIndex: 20000,
  },
  modalText: {
    paddingTop: 5,
    paddingLeft: 5,
    height: '50%',
    fontSize: 14,
    '&:hover': {
      backgroundColor: darken('#ffffff', 0.15),
      cursor: 'pointer',
    },
  },
  disabledModalText: {
    '&:hover': {
      backgroundColor: darken('#ffffff', 0.15),
      cursor: 'not-allowed',
    },
  },
  dropdown: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
    maxHeight: 300,
    overflowY: 'auto',
    position: 'absolute',
    top: 24,
    zIndex: 100,
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

export default React.memo(observer(Content));
