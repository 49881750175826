import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

type Props = {
  cell: {
    value: Date | null;
  };
};

const DateTimeDisplay: React.FC<Props> = props => {
  const { cell } = props;
  const { value } = cell;

  if (!value) {
    return <span>Not provided</span>;
  }
  const display = moment(value).format('MM/DD/YY HH:mm:ss');

  return (
    <>
      <span>{display}</span>
    </>
  );
};

export default observer(DateTimeDisplay);
