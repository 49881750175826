import { Input, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useStores } from 'doc-mate-store/lib/hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
type Props = {
  cell: {
    value: [number, number, boolean];
  };
};

const PrioritySelect: React.FC<Props> = props => {
  const classes = useStyles();
  const { cell } = props;

  const {
    value: [priority, loadId, isDispatcherOrBroker], // eslint-disable-line @typescript-eslint/no-unused-vars
  } = cell;
  const { rootStore } = useStores();
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      event.stopPropagation();
      const newValue = event.target.value as number;
      if (newValue !== priority) {
        const load = rootStore.loadTableRows.get(`${loadId}`);
        if (load) {
          setOpen(false);
          const response = await load.updatePriority(newValue);
          if (response && !response.ok) {
            console.log('[DEBUG] SelectWrapper error:', response.errors);
          }
        }
      }

      setOpen(false);
    },

    [priority], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isDispatcherOrBroker) {
        event.preventDefault();
        event.stopPropagation();
        // setOpen(!open);
        setOpen(true);
      }
    },
    [isDispatcherOrBroker],
  );

  const handleMouseDown = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  return (
    <>
      <Select
        className={classes.select}
        fullWidth
        input={<Input disableUnderline />}
        onChange={handleChange}
        onClick={handleClick}
        value={priority}
        open={open}
        onMouseDown={handleMouseDown}
        disabled={!isDispatcherOrBroker}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number: number) => (
          <MenuItem key={`${loadId}-${number}`} value={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    '& > .MuiSelect-select.MuiSelect-select': {
      backgroundColor: 'transparent',
      paddingRight: 0,
    },
  },
  popUpText: {
    fontSize: 12,
    padding: 5,
  },
}));

export default React.memo(observer(PrioritySelect));
